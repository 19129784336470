import React, { PureComponent } from 'react';
import sizes from 'react-sizes';
import styled, { css } from 'styled-components';
import { NavLinks, DynamicEventsLogo, RenderWhenReady } from 'components';

class Header extends PureComponent {
  render() {
    const { makeHeaderSticky, viewportWidth } = this.props;
    return (
      <RenderWhenReady>
        <Wrapper makeHeaderSticky={makeHeaderSticky} viewportWidth={viewportWidth}>
          <Nav>
            <DynamicEventsLogo makeHeaderSticky={makeHeaderSticky} viewportWidth={viewportWidth} />
            <DesktopNavLinksWrapper>
              {viewportWidth >= 1200 && <NavLinks makeHeaderSticky={makeHeaderSticky} />}
            </DesktopNavLinksWrapper>
          </Nav>
        </Wrapper>
      </RenderWhenReady>
    );
  }
}

export default sizes(({ width }) => ({ viewportWidth: width && width }))(Header);

const Wrapper = styled.header`
  position: fixed;
  max-width: 2560px;
  width: 100vw;
  z-index: 3;
  display: flex;
  justify-content: center;

  ${({ makeHeaderSticky, viewportWidth }) =>
    makeHeaderSticky || viewportWidth < 1200
      ? css`
          background: rgba(000, 000, 000, 0.725);
          transform: translate3d(0, -35px, 0);
          transition: transform 0.5s ease-in-out, background 0.5s ease-in-out;
        `
      : css`
          background: transparent;
          transform: translate3d(0, 0, 0);
          transition: transform 0.5s ease-in-out, background 0.5s ease-in-out;
        `}
`;

const Nav = styled.nav`
  max-width: 1264px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 47px 2em 4px;
`;

const DesktopNavLinksWrapper = styled.div`
  display: flex;
  max-width: 896px;
  width: 100%;
  justify-content: space-between;
  margin-left: 26px;
  margin-right: 13px;
`;

// const Button = styled(AnchorLink)`
//   background-color: var(--dynamic-yellow);
//   border-radius: 1.063em;
//   padding: 0.3em 0.85em;
//   text-align: center;
//   text-decoration: none;
//   position: relative;
//   bottom: 0.3em;
//   width: 116px;
//   height: 30px;
// `;

// const ButtonText = styled.span`
//   color: #fff;
//   font-size: 0.95rem;
//   letter-spacing: 0.01em;
//   font-weight: bold;
// `;
