import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'components';

const formInitialState = () => ({
  firstName: '',
  surname: '',
  email: '',
  phone: '',
  company: '',
  message: '',
  addToMailingList: 'No'
});

const action = `https://www2.dynamicevents.ie/l/405332/2024-11-25/23jg5lk?success_location=${encodeURIComponent(
  'https://www.dynamicevents.ie/message-success/'
)}`;

const Form = ({ location }) => {
  const [formValues, setFormValues] = useState(formInitialState());
  const [submittingForm, setSubmittingForm] = useState(false);
  const [submitButtonIsHovered, setSubmitButtonIsHovered] = useState(false);

  const formRef = useRef(null);

  useEffect(
    () => () => {
      if (submittingForm) {
        setSubmittingForm(false);
      }
    },
    []
  );

  /* Might be able to get rid of this check and just return e.target.checked if we make some changes
  in Pardot. Will also have to refactor the 'Yes' string in the default state to a true boolean, and
  the 'checked' prop on HiddenDefaultCheckbox to reference that boolean. And remove the 'value' prop
  on it too. */
  const handleCheckboxValue = (checkboxIsChecked) => (checkboxIsChecked ? 'Yes' : 'No');

  const handleChange = (e) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]:
        e.target.type === 'checkbox' ? handleCheckboxValue(e.target.checked) : e.target.value
    }));
  };

  const { firstName, surname, email, phone, company, message, addToMailingList } = formValues;

  return (
    <StyledForm
      method="POST"
      enctype="application/x-www-form-urlencoded"
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        const ref = formRef.current;
        ref.action = action;
        ref.submit();
      }}>
      <div hidden style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
        <Label htmlFor="pardot_extra_field">
          Don't fill this out:
          <Input type="text" id="pardot_extra_field" name="pardot_extra_field" />
        </Label>
      </div>
      <div hidden>
        <Label htmlFor="Website_Referral_URL">
          Website Referral URL
          <Input
            type="text"
            id="Website_Referral_URL"
            name="Website_Referral_URL"
            value={location.href}
            readOnly
          />
        </Label>
      </div>
      <Label>
        First Name<Asterisks>*</Asterisks>
        <Input
          aria-label="Enter your first name"
          name="firstName"
          onChange={(e) => handleChange(e)}
          placeholder="Enter your first name"
          required
          type="text"
          value={firstName}
        />
      </Label>
      <Label>
        Surname<Asterisks>*</Asterisks>
        <Input
          aria-label="Enter your surname"
          name="surname"
          onChange={(e) => handleChange(e)}
          placeholder="Enter your surname"
          required
          type="text"
          value={surname}
        />
      </Label>
      <Label>
        Company E-mail<Asterisks>*</Asterisks>
        <Input
          aria-label="Enter your company email"
          name="email"
          onChange={(e) => handleChange(e)}
          placeholder="Enter company email"
          required
          type="email"
          value={email}
        />
      </Label>
      <Label>
        Phone<Asterisks>*</Asterisks>
        <Input
          aria-label="Enter your phone number"
          name="phone"
          onChange={(e) => handleChange(e)}
          placeholder="Enter phone number"
          required
          type="tel"
          value={phone}
        />
      </Label>
      <Label>
        Company<Asterisks>*</Asterisks>
        <Input
          aria-label="Enter your company name"
          name="company"
          onChange={(e) => handleChange(e)}
          placeholder="Enter company name"
          required
          type="text"
          value={company}
        />
      </Label>
      <Label>
        Message<Asterisks>*</Asterisks>
        <MessageInput
          aria-label="Enter your message"
          as="textarea"
          cols="30"
          name="message"
          onChange={(e) => handleChange(e)}
          placeholder="If known, please include as much detail as possible, i.e. numbers, date of event, venue, location, reason for event, etc."
          required
          rows="10"
          value={message}
        />
      </Label>
      <Label>
        Would you like to be added to our mailing list?
        <HiddenDefaultCheckbox
          aria-label="Would you like to be added to our mailing list"
          name="addToMailingList"
          onChange={(e) => handleChange(e)}
          type="checkbox"
          checked={addToMailingList === 'Yes'}
          value={addToMailingList === 'Yes' ? 'Yes' : 'No'}
        />
        <CustomCheckbox>
          <span>Yes</span>
        </CustomCheckbox>
      </Label>
      <Button
        type="submit"
        width="140px"
        height="40px"
        text={
          submittingForm ? (
            <Status submitButtonIsHovered={submitButtonIsHovered}>Submitting</Status>
          ) : (
            'Submit'
          )
        }
        onMouseEnter={() => setSubmitButtonIsHovered(true)}
        onMouseLeave={() => setSubmitButtonIsHovered(false)}
        handleClick={() => {
          if (firstName && surname && email && company && message) {
            const dynamicEventsGetQuoteData = {
              firstName,
              surname,
              email,
              phoneNumber: phone
            };
            window.sessionStorage.setItem(
              'dynamicEventsGetQuoteData',
              JSON.stringify(dynamicEventsGetQuoteData)
            );
            setSubmittingForm(true);
          }
        }}
        ariaLabel="submit form"
        fontColor="--dynamic-dark-grey"
        buttonColor="--dynamic-yellow"
        buttonHoverColor="--dynamic-dark-grey"
        buttonPressedColor="--dynamic-black"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    margin-top: 20px;

    @media screen and (min-width: 316px) {
      margin-top: 40px;
    }

    @media screen and (min-width: 450px) {
      margin-top: 0;
    }
  }
`;

const Label = styled.label`
  color: var(--dynamic-dark-grey);
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.375em;
  margin-top: 1em;
  width: 100%;

  &:first-of-type {
    margin-top: 1.425em;
  }

  &:last-of-type {
    position: relative;
    user-select: none;

    &:hover input + span {
      background-color: #ccc;
    }

    & input:checked + span {
      background-color: #fff;
    }

    & input:checked + span:after {
      display: block;
    }
  }
`;

const Input = styled.input`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #fff;
  font-size: 0.925rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-top: 5px;
  outline-color: var(--dynamic-yellow);
  padding: 0.75em;
  width: 100%;

  &::placeholder {
    color: #bdbdbd;
    font-style: italic;
    font-family: 'proxima-nova', sans-serif;
  }
`;

const Asterisks = styled.span`
  color: var(--dynamic-red);
  margin-left: 3px;
`;

const MessageInput = styled(Input)`
  height: 100px;
  resize: none;
  width: 100%;
`;

const HiddenDefaultCheckbox = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`;

const CustomCheckbox = styled.span`
  background-color: #eee;
  border-radius: 2px;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 25px;
  width: 25px;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }

  &:after {
    border-style: solid;
    border-width: 0 3px 3px 0;
    height: 12.5px;
    left: 9px;
    top: 5px;
    transform: rotate(45deg);
    width: 7px;
  }

  /* 'Yes' */
  span {
    font-weight: bold;
    left: 34px;
    letter-spacing: 0.02em;
    position: absolute;
    top: 4px;
  }
`;

const Status = styled.span`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;

  &:after {
    animation: ${({ submitButtonIsHovered }) =>
      submitButtonIsHovered
        ? 'dotsHovered 1s steps(5, end) infinite'
        : 'dots 1s steps(5, end) infinite'};
    content: ' .';
    margin-left: 0.125rem;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #4f4f4f;
      text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.4rem 0 0 #4f4f4f, 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.4rem 0 0 #4f4f4f, 0.8rem 0 0 #4f4f4f;
    }
  }

  @keyframes dotsHovered {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #fff;
      text-shadow: 0.4rem 0 0 rgba(0, 0, 0, 0), 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.4rem 0 0 #fff, 0.8rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.4rem 0 0 #fff, 0.8rem 0 0 #fff;
    }
  }
`;

export default Form;
